import { httpClient } from '@/utils/http-client'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logRocketIdentify } from '@/services/marketing'

const verifyEmployerEmail = async (verificationCode: string) => {
    const postBody = {
        auth: verificationCode,
    }
    const response = await httpClient.post('/verifyEmployerEmail', postBody)
    return response
}

// TODO: delete this?
const isEmployerEmailVerified = async () => {
    const response = await httpClient.get('/verifyEmployerEmail')
    return response
}

const getLead = async () => {
    const phoneNumber = appSessionStorage.getItem(localStorageKey.phoneNumber)
    const inviteCode = appSessionStorage.getItem(localStorageKey.inviteCode) || appSessionStorage.getItem(localStorageKey.pifInviteCode)
    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)

    const payload = {
        phoneNumber,
        sessionId,
        inviteCode,
    }
    const response = await httpClient.post('/lead', payload)
    saveAuthResponse(response)
    logRocketIdentify()
    return response
}

const createCoApplicantLead = async () => {
    const response = await httpClient.get('/createCoApplicant')
    saveCoApplicantAuthResponse(response)
    return response
}

const saveAuthResponse = (response: { data: any }) => {
    if (response.data.success) {
        appSessionStorage.setItem(localStorageKey.jwtTokens, JSON.stringify(response.data.payload.jwtTokens))
        appSessionStorage.setItem(localStorageKey.firstName, response.data.payload.firstName || '')
        appSessionStorage.setItem(localStorageKey.applicantId, response.data.payload.applicantId.toString())
        appSessionStorage.setItem(localStorageKey.loanApplicationId, response.data.payload.loanApplicationId.toString())
        appSessionStorage.setItem(localStorageKey.experimentName, response.data.payload.experimentName)
    }
}

const saveCoApplicantAuthResponse = (response: { data: any }) => {
    if (response.data.success) {
        appSessionStorage.setItem(localStorageKey.coApplicantJwtTokens, JSON.stringify(response.data.payload.coApplicantJwtTokens))
    }
}

export { getLead, createCoApplicantLead, verifyEmployerEmail, isEmployerEmailVerified }
